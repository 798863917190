<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">商户订单管理</div>
      <el-form :inline="true" size="mini">
        <el-form-item label="商户订单:">
          <el-input v-model="p.outTradeNo" style="width: 230px;" clearable></el-input>
        </el-form-item>
        <el-form-item label="系统订单:">
          <el-input v-model="p.transactionId" style="width: 230px;" clearable></el-input>
        </el-form-item>
        <br v-if="this.sa_admin.role == 2" />
        <el-form-item v-if="this.sa_admin.role == 2" label="商户昵称:">
          <el-input v-model="p.nickName" clearable style="width: 230px;"></el-input>
        </el-form-item>
        <el-form-item label-width="100x" v-if="this.sa_admin.role == 2" label="Uid/OpenID:">
          <el-input v-model="p.openId" clearable style="width: 230px;"></el-input>
        </el-form-item>
        <br>
        <el-form-item label="通道订单:">
          <el-input v-model="p.orderId" style="width: 230px;" clearable></el-input>
        </el-form-item>
        <el-form-item label="时间查询:">
          <el-date-picker v-model="p.startTime" type="date" value-format="yyyy-MM-dd" placeholder="开始日期"></el-date-picker>
          -
          <el-date-picker v-model="p.stopTime" type="date" value-format="yyyy-MM-dd" placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <br />
        <el-form-item label="通道类型:">
          <el-select size="mini" v-model="p.payType" filterable clearable @blur="selectPayType">
            <el-option label="全部" :value="null"></el-option>
            <el-option label="电商通H5" :value="1"></el-option>
            <el-option label="微信扫码" :value="2"></el-option>
            <el-option label="直付通H5" :value="3"></el-option>
            <el-option label="电商通内付" :value="4"></el-option>
            <el-option label="微信内付" :value="5"></el-option>
            <el-option label="微信合单H5" :value="6"></el-option>
            <el-option label="电商通扫码" :value="10"></el-option>
            <el-option label="支付宝当面付" :value="7"></el-option>
            <el-option label="支付宝商城网站" :value="12"></el-option>
            <el-option label="支付宝个码" :value="13"></el-option>
            <el-option label="黄金-支付宝个码" :value="27"></el-option>
            <el-option label="支付宝预授权" :value="14"></el-option>
            <el-option label="支付宝手机网站" :value="16"></el-option>
            <el-option label="微信APP-IOS单端" :value="19"></el-option>
            <el-option label="微信赞赏码" :value="22"></el-option>
            <el-option label="订单码支付" :value="23"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态筛选:">
          <el-radio-group size="mini" v-model="p.status">
            <el-radio-button label="null">全选</el-radio-button>
            <el-radio-button label="0">未支付</el-radio-button>
            <el-radio-button label="1">已支付</el-radio-button>
            <el-radio-button label="2">通知失败</el-radio-button>
            <el-radio-button label="3">黑名单</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="min-width: 0px">
          <el-button type="primary" plain icon="el-icon-search" @click="
            p.page = 1;
          f5();
          ">查询</el-button>
          <el-button type="success" plain icon="el-icon-refresh" @click="
            p.page = 1;
          f5();">刷新</el-button>
        </el-form-item>
      </el-form>
      <!-- <div class="c-title">数据列表</div> -->
      <el-table :data="dataList" size="mini" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column show-overflow-tooltip label="商户订单" prop="outTradeNo" width="230px">
        </el-table-column>
        <!-- <el-table-column label="三方交易订单" prop="wechatOrder" width="220px">
        </el-table-column> -->
        <el-table-column label="订单金额/实付金额" prop="amount" width="150px">
          <template slot-scope="s">
            <el-tag type="danger" size="mini">￥{{ s.row.amount }}/￥{{ s.row.reallyAmount }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="订单描述" prop="attach" width="100px"></el-table-column>
        <el-table-column label="创建时间" prop="createdTime" min-width="145px"></el-table-column>
        <el-table-column label="订单状态" min-width="100px">
          <template slot-scope="s">
            <el-tag v-if="s.row.state == 2" type="danger" size="mini">通知失败</el-tag>
            <el-tag v-if="s.row.state == 1" type="success" size="mini">已支付</el-tag>
            <el-tag v-if="s.row.state == 0" type="warning" size="mini">未支付</el-tag>
            <el-tag v-if="s.row.state == 3" type="danger" size="mini">黑名单</el-tag>
            <el-tag v-if="s.row.state == 4" type="warning" size="mini">下单频繁</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="通道类型" width="120px">
          <template slot-scope="s">
            <p v-if="s.row.payType == 1" size="info">微信H5</p>
            <p v-if="s.row.payType == 2" size="info">微信扫码</p>
            <p v-if="s.row.payType == 3" size="info">直付通H5</p>
            <p v-if="s.row.payType == 4" size="info">电商通内付</p>
            <p v-if="s.row.payType == 5" size="info">微信内付</p>
            <p v-if="s.row.payType == 6" size="info">微信合单H5</p>
            <p v-if="s.row.payType == 7" size="info">支付宝当面付</p>
            <p v-if="s.row.payType == 8" size="info">悦浮微信</p>
            <p v-if="s.row.payType == 9" size="info">悦浮支付宝</p>
            <p v-if="s.row.payType == 10" size="info">电商通合单扫码</p>
            <p v-if="s.row.payType == 11" size="info">淘宝直跳</p>
            <p v-if="s.row.payType == 12" size="info">支付宝商城网站</p>
            <p v-if="s.row.payType == 13" size="info">支付宝个码</p>
            <p v-if="s.row.payType == 27" size="info">黄金-支付宝个码</p>
            <p v-if="s.row.payType == 14" size="info">支付宝预授权</p>
            <p v-if="s.row.payType == 15" size="info">微信收款单</p>
            <p v-if="s.row.payType == 16" size="info">支付宝手机网站</p>
            <p v-if="s.row.payType == 18" size="info">小荷包客服</p>
            <p v-if="s.row.payType == 19" size="info">微信APP-IOS单端</p>
            <p v-if="s.row.payType == 20" size="info">拉卡拉-微信</p>
            <p v-if="s.row.payType == 22" size="info">微信赞赏码</p>
            <p v-if="s.row.payType == 23" size="info">订单码支付</p>
            <p v-if="s.row.payType == 24" size="info">直付通PC</p>
            <p v-if="s.row.payType == 25" size="info">当面付-游戏网站</p>
            <p v-if="s.row.payType == 26" size="info">当面付-商城网站</p>
            <p v-if="s.row.payType == 28" size="info">支付宝APP支付</p>
            <p v-if="s.row.payType > 1000" size="info">自定义</p>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="收款商户号" prop="wechatMch" width="130px">
        </el-table-column>
        <el-table-column v-if="this.sa_admin.role == 2" label="所属码队" prop="codeMchName" min-width="120px">
        </el-table-column>
        <el-table-column v-if="this.sa_admin.role == 2" label="创建商户" prop="mchNickName" min-width="120px">
        </el-table-column>
        <el-table-column prop="address" label="操作" width="150px" fixed="right">
          <template slot-scope="s">
            <el-button class="c-btn" type="success" plain icon="el-icon-view" @click="get(s.row)">详情</el-button>
            <el-button class="c-btn" v-if="s.row.state == 1 || s.row.state == 2 || s.row.payType == 18" type="info" icon="el-icon-thumb" plain @click="bd(s.row)">补单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes" :current-page.sync="p.page" :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
      <OrderDetails ref="order-details"></OrderDetails>
    </div>
  </div>
</template>

<script>
import OrderDetails from "./order-details.vue";
export default {
  components: { OrderDetails },
  data() {
    return {
      p: {
        // 查询参数
        status: null,
        outTradeNo: "",
        orderId: "",
        transactionId: "",
        subTransactionId: "",
        nickName: "",
        mchId: "",
        codeMchId: "",
        startTime: null, // 本月一号
        stopTime: null, // 本月当日
        payType: null,
        openId: "",
        page: 1,
        limit: 10,
      },
      b: {
        // 查询参数
        status: null,
        outTradeNo: "",
        transactionId: "",
        page: 1,
        limit: 10,
      },
      dataCount: 0,
      dataList: [],
      rowCenter: {
        "text-align": "center",
        "width": "50%"
      },
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      if (this.sa_admin.role == 2 || this.sa_admin.role == 3) {
        var defaultCfg = {
          isBody: true, //是否是请求体请求
        };
        if (this.sa_admin.role == 3) {
          this.p.codeMchId = this.sa_admin.mchId;
        }
        this.sa.ajax(
          "/v2/admin/queryMerchantOrders",
          this.p,
          function (res) {
            this.dataList = res.data.records;
            this.dataCount = res.data.total;
          }.bind(this),
          defaultCfg
        );
      } else {
        defaultCfg = {
          type: "get",
        };
        this.sa.ajax(
          "/v1/payOrder/getMerchantOrders",
          this.p,
          function (res) {
            this.dataList = res.data.records;
            this.dataCount = res.data.total;
          }.bind(this),
          defaultCfg
        );
      }
    },
    selectPayType(even) {
      if (even.target.value) {
        this.p.payType = even.target.value;
      }
    },
    //补单
    bd: function (data) {
      this.sa.confirm(
        "是否进行补单，此操作不可撤销",
        function () {
          this.sa.ajax(
            "/v1/payOrder/setBd?out_trade_no=" + data.outTradeNo,
            function () {
              this.sa.ok("补单成功");
            }.bind(this)
          );
        }.bind(this)
      );
    },
    getStartDate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      // var hour = " 00:00:00"; //默认时分秒 如果传给后台的格式为年月日时分秒，就需要加这个，如若不需要，此行可忽略
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date - 5;
      date = date.toString().padStart(2, "0");
      var defaultDate = `${year}-${month}-${date}`;//
      return defaultDate;
    },
    getEndDate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      // var hour = " 00:00:00"; //默认时分秒 如果传给后台的格式为年月日时分秒，就需要加这个，如若不需要，此行可忽略
      month = month + 1;
      date = date + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0")
      var defaultDate = `${year}-${month}-${date}`;//
      return defaultDate;
    },
    // 查看
    get: function (data) {
      if (window.matchMedia("(max-width: 750px)").matches) {
        var str = "<div>";
        str += "<p>外部商户订单号：" + data.outTradeNo + "</p>";
        str += "<p>系统订单号：" + data.transactionId + "</p>";
        str += "<p>通道交易订单：" + data.wechatOrder + "</p>";
        str += "<p>通道类型：" + data.payType + "</p>";
        if (this.sa_admin.role == 2) {
          str += "<p>所 属 码 队：" + data.codeMchName + "</p>";
        }
        if (this.sa_admin.role == 2) {
          str += "<p>创 建 商 户：" + data.mchNickName + "</p>";
          str += "<p>二级商户号：" + data.wechatMch + "</p>";
        }
        str += "<p>附加数据：" + data.attach + "</p>";
        str += "<p>订单金额：￥" + data.amount + "</p>";
        str += "<p>客户ip：" + data.clientIp + "</p>";
        if (data.state == 0) {
          str += "<p>订单状态：未支付</p>";
        } else if (data.state == 1) {
          str += "<p>订单状态：已支付</p>";
        } else {
          str += "<p>订单状态：已支付,异步处理失败</p>";
        }
        str += "<p>创建时间：" + data.createdTime + "</p>";
        if (data.state == 1) {
          str += "<p>支付时间：" + data.payDate + "</p>";
          str += "<p>关闭时间：" + data.closeDate + "</p>";
        }
        str += "<p>同步地址：" + data.returnUrl + "</p>";
        str += "<p>异步地址：" + data.notifyUrl + "</p>";
        str += "<p>支付链接：" + data.payUrl + "</p>";
        str += "</div>";
        this.sa.alert(str);
      } else {
        this.$refs["order-details"].open(data);
        this.drawerVisible = true;
      }
    },
  },
  created: function () {
    // this.p.startTime = this.getStartDate();
    // this.p.stopTime = this.getEndDate();
    this.f5();

  },
};
</script>

<style></style>
