<template>
  <el-dialog top="6vh" title="订单详情" :append-to-body="true" :visible.sync="drawerVisible" min-size="48%" size="48%" direction="rtl">
    <div class="vue-box" style="background-color:#ffffff;">
      <el-descriptions :contentStyle="rowCenter" :column="2" border size="mini">
        <el-descriptions-item label="商户订单号"><el-tag type="info" size="mini">{{details.outTradeNo}}</el-tag></el-descriptions-item>
        <el-descriptions-item label="系统订单号">{{details.transactionId}}</el-descriptions-item>
        <el-descriptions-item label="通道交易号">{{details.wechatOrder || '暂无'}}</el-descriptions-item>
        <el-descriptions-item label="商户号">{{details.mchId}}</el-descriptions-item>
        <el-descriptions-item label="支付金额">
          <el-tag size="mini">￥{{details.amount}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="实付金额">
          <el-tag type="danger" size="mini">￥{{details.reallyAmount}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="订单状态">
          <el-tag v-if="details.state == 3" type="danger" size="mini">黑名单</el-tag>
          <el-tag v-if="details.state == 4" type="danger" size="mini">下单频繁</el-tag>
          <el-tag v-if="details.state == 2" type="danger" size="mini">通知失败</el-tag>
          <el-tag v-if="details.state == 1" type="success" size="mini">已支付</el-tag>
          <el-tag v-if="details.state == 0" type="warning" size="mini">未支付</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">{{details.createdTime}}</el-descriptions-item>
        <el-descriptions-item v-if="details.state != 0" label="支付成功时间">
          <a v-if="details.state == 0">待支付</a>
          <a v-if="details.state == 1 || details.state == 2">{{details.payDate}}</a>
        </el-descriptions-item>
        <el-descriptions-item v-if="details.state != 0" label="订单关闭时间" :span="3">
          <a v-if="details.state == 0">待支付</a>
          <a v-if="details.state == 1 || details.state == 2">{{details.closeDate}}</a>
        </el-descriptions-item>
        <el-descriptions-item label="异步通知地址" :span="3">{{details.notifyUrl}}</el-descriptions-item>
        <el-descriptions-item label="页面跳转地址" :span="3">{{details.returnUrl}}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions :contentStyle="rowCenter" :column="2" border size="mini">
        <el-descriptions-item label="订单描述"><el-tag type="info" size="mini">{{details.attach}}</el-tag></el-descriptions-item>
        <el-descriptions-item label="支付通道">
          <el-tag v-if="details.payType == 1" type="info" size="mini">微信H5</el-tag>
          <el-tag v-if="details.payType == 2" type="info" size="mini">微信扫码</el-tag>
          <el-tag v-if="details.payType == 5" type="info" size="mini">微信内付</el-tag>
          <el-tag v-if="details.payType == 3" type="info" size="mini">直付通H5</el-tag>
          <el-tag v-if="details.payType == 4" type="info" size="mini">电商通内付</el-tag>
          <el-tag v-if="details.payType == 6" type="info" size="mini">微信合单H5</el-tag>
          <el-tag v-if="details.payType == 7" type="info" size="mini">支付宝当面付</el-tag>
          <el-tag v-if="details.payType == 8" type="info" size="mini">悦浮微信</el-tag>
          <el-tag v-if="details.payType == 9" type="info" size="mini">悦浮支付宝</el-tag>
          <el-tag v-if="details.payType == 10" type="info" size="mini">电商通扫码</el-tag>
          <el-tag v-if="details.payType == 11" type="info" size="mini">淘宝直跳</el-tag>
          <el-tag v-if="details.payType == 12" type="info" size="mini">支付宝商城网站</el-tag>
          <el-tag v-if="details.payType == 13" type="info" size="mini">支付宝个码</el-tag>
          <el-tag v-if="details.payType == 27" type="info" size="mini">黄金-支付宝个码</el-tag>
          <el-tag v-if="details.payType == 14" type="info" size="mini">支付宝预授权</el-tag>
          <el-tag v-if="details.payType == 15" type="info" size="mini">微信收款单</el-tag>
          <el-tag v-if="details.payType == 16" type="info" size="mini">支付宝手机网站</el-tag>
          <el-tag v-if="details.payType == 18" type="info" size="mini">小荷包客服</el-tag>
          <el-tag v-if="details.payType == 19" type="info" size="mini">微信APP-IOS单端</el-tag>
          <el-tag v-if="details.payType == 20" type="info" size="mini">拉卡拉-微信</el-tag>
          <el-tag v-if="details.payType == 22" type="info" size="mini">微信赞赏码</el-tag>
          <el-tag v-if="details.payType == 23" type="info" size="mini">订单码支付</el-tag>
          <el-tag v-if="details.payType == 24" type="info" size="mini">直付通PC</el-tag>
          <el-tag v-if="details.payType == 25" type="info" size="mini">当面付-游戏网站</el-tag>
          <el-tag v-if="details.payType == 26" type="info" size="mini">当面付-商城网站</el-tag>
          <el-tag v-if="details.payType == 28" type="info" size="mini">支付宝APP支付</el-tag>
          <el-tag v-if="details.payType > 1000" type="info" size="mini">自定义</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="收款商户号">{{details.wechatMch}}</el-descriptions-item>
        <el-descriptions-item :span="2" label="订单有效期">{{details.expiryDate}}分钟</el-descriptions-item>
        <el-descriptions-item v-if="this.sa_admin.role == 2" label="所属码队">{{details.codeMchName}}</el-descriptions-item>
        <el-descriptions-item label="客户IP">{{details.clientIp || '暂无'}}</el-descriptions-item>
        <el-descriptions-item label="支付uid">{{details.openId || '暂无'}}</el-descriptions-item>
        <el-descriptions-item v-if="this.sa_admin.role == 2" label="创建商户">{{details.mchNickName}}</el-descriptions-item>
        <!-- <el-descriptions-item label="下游通知状态"><el-tag type="success"   size="mini">通知完成</el-tag></el-descriptions-item>
        <el-descriptions-item label="通知次数">1</el-descriptions-item> -->
        <el-descriptions-item label="是否支持分账">
          <el-tag v-if="details.profitShare == 0" type="danger" size="mini">不支持</el-tag>
          <el-tag v-if="details.profitShare == 1" type="success" size="mini">支持</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="订单下单渠道">
          <el-tag v-if="details.orderType == 0" size="mini">公共下单</el-tag>
          <el-tag v-if="details.orderType == 1" size="mini">易支付V1</el-tag>
          <el-tag v-if="details.orderType == 2" size="mini">易支付V2</el-tag>
          <el-tag v-if="details.orderType == 3" size="mini">易支付V3</el-tag>
          <el-tag v-if="details.orderType == 4" size="mini">易支付V4</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="是否在浏览器打开">
          <el-tag v-if="details.isVisit == 1" type="success" size="mini">是</el-tag>
          <el-tag v-if="details.isVisit == 0" type="info" size="mini">否</el-tag>
        </el-descriptions-item>
      </el-descriptions>
      <!-- <el-divider>客户端信息</el-divider>
      <el-descriptions :contentStyle="rowCenter" :column="2">
        <el-descriptions-item label="浏览器">
          <el-tag type="info"   size="mini">{{visitInfo.browser}}</el-tag></el-descriptions-item>
        <el-descriptions-item label="设备类型">{{visitInfo.deviceType}}</el-descriptions-item>
        <el-descriptions-item label="是否在微信打开">
          <el-tag v-if="visitInfo.isWechat == 1" type="success" size="mini"  >是</el-tag>
          <el-tag v-if="visitInfo.isWechat == 0" type="info" size="mini"  >否</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="是否在支付宝打开">
          <el-tag v-if="visitInfo.isAlipay == 1" type="success" size="mini"  >是</el-tag>
          <el-tag v-if="visitInfo.isAlipay == 0" type="info" size="mini"  >否</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="是否在浏览器打开">
          <el-tag v-if="details.isVisit == 1" type="success" size="mini"  >是</el-tag>
          <el-tag v-if="details.isVisit == 0" type="info" size="mini"  >否</el-tag>
        </el-descriptions-item>
      </el-descriptions> -->

      <el-divider></el-divider>
      <el-descriptions :contentStyle="rowCenter" :column="2" border size="mini">
        <el-descriptions-item label="支付链接" :span="3">{{details.payUrl}}</el-descriptions-item>
      </el-descriptions>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      drawerVisible: false,
      dataCount: 0,
      visitInfo: {
        deviceType: "false",
        isAlipay: false,
        isMoble: false,
        browser: "false",
      },
      details: {
        alipaySubId: "",
        amount: "",
        attach: "",
        clientIp: "",
        closeDate: null,
        codeMchId: "",
        codeMchName: "",
        combineOrder: "",
        createdTime: "",
        customWay: "",
        expiryDate: "",
        mchId: "",
        mchNickName: "",
        notifyUrl: "",
        orderType: "",
        outTradeNo: "",
        payDate: null,
        payType: "",
        payUrl: "",
        profitShare: "",
        reallyAmount: "",
        returnUrl: "",
        state: "",
        transactionId: "",
        wechatMch: "",
        wechatOrder: "",
      },
      rowCenter: {
        // "text-align": "center",
        // "width": "50%"
      },
    };
  },
  methods: {
    // 数据刷新
    open: function (data) {
      this.details = data;
      this.visitInfo = JSON.parse(data.visitInfo);
      this.drawerVisible = true;
    },
    // 查看
    get: function () {

    },
  },
  created: function () {
  },
};
</script>
